import { Pipe, PipeTransform } from '@angular/core';
import { Translator } from './services/translator/translator.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  constructor(private translate: Translator) { }

  transform(key: any): any {
    let trad = this.translate.GetTranslations();
    return trad[key] || key;
  }

}
