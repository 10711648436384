<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
  <app-buttons
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    [buttonsWrap]="'panel'"
    [modificar]="modificar"
  ></app-buttons>
  <div class="editor-registro">
    <div class="divForm">
      <form [formGroup]="editRegistroForm" (onSubmit)="submit()">
        <div class="form-group row">
          <div class="col-sm-12 col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.Nombre }}</mat-label>
              <input
                matInput
                formControlName="Nombre"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('Nombre')">
                {{ getErrorMessage("Denominacion") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-12 col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.Descripcion }}</mat-label>
              <input
                matInput
                formControlName="Descripcion"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('Descripcion')">
                {{ getErrorMessage("Descripcion") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group row">
          <!--div class="half">
            <app-input-filter *ngIf="user && userTypes && flagInput" formControlText="Tipo"
            [label]="translation.Tipo" [placeHolderTranslation]="translation.Tipo" 
            [list]="userTypes" textShow="Nombre" dataInput="pkId" required="true"
            [data]="user.Tipo" (close)="closeInputFilter($event)"></app-input-filter>
          </div-->

          <div class="col-sm-12 col-md-12">
            <!--             <mat-form-field appearance="fill">
              <mat-label>{{translation.Cliente}}</mat-label>
              <mat-select>
                <mat-option *ngFor="let clientes_select of listaClientes" [value]="clientes_select.value">
                  {{clientes_select.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <app-input-filter
              *ngIf="listaClientes && listaClientes.length > 0"
              formControlText="CodigoCliente"
              [disabled]="listaClientes.length < 1"
              [label]="translation.Cliente"
              [placeHolderTranslation]="translation.Cliente"
              [list]="listaClientes"
              textShow="Nombre"
              dataInput="Id"
              required="true"
              [data]="tipoCodigoPromociones['CodigoCliente']"
              (close)="closeInputFilter($event)"
            >
            </app-input-filter>
          </div>

          <div class="col-sm-12 col-md-12">
            <app-input-filter
              *ngIf="
                listaCanales && listaCanales.length > 0 && tipoCodigoPromociones
              "
              formControlText="CanalId"
              [disabled]="listaCanales.length < 1"
              [label]="translation.Canal"
              [placeHolderTranslation]="translation.Canal"
              [list]="listaCanales"
              textShow="canalsubcanal"
              dataInput="IdCanal"
              required="false"
              [data]="tipoCodigoPromociones.CanalId"
              (close)="closeInputFilter($event)"
            >
            </app-input-filter>
          </div>

          <!--<div class="col-sm-12 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.Cliente}}</mat-label>
            <input type="number" matInput formControlName="Cliente" class="editor-campo-registro">
            <mat-error *ngIf="validateFields('Cliente')">
              {{getErrorMessage("Cliente")}}
            </mat-error>
          </mat-form-field>
        </div>-->
        </div>
        <div class="matslide">
          <mat-label
            >{{ translation.Baja }}
            <mat-slide-toggle
              matInput
              name="Baja"
              formControlName="DeBaja"
            ></mat-slide-toggle>
          </mat-label>
        </div>

        <div style="margin: 20px 5px 0 5px; text-align: center">
          <mat-label>{{ translation.Centros }}</mat-label>
          <customan-dual-list
            [source]="centrosSinAsignar"
            [(destination)]="centrosAsignados"
            key="pkId"
            display="Nombre"
            [format]="format"
            [filter]="true"
          >
          </customan-dual-list>
        </div>


        <!-- TABLAS INTERACTIVAS -->
        <!--         <app-filter [tableName]="'usuarios'"></app-filter>
        <app-table-interactive [tableName]="'usuarios'"></app-table-interactive> -->
        <app-table-interactive
          #ti_tiposEntrada
          [tableName]="'tbTiposCodigosPromocionales_tiposEntrada'"
          [parent_pkId]="pkId"
          (guardatabla)="submit(false)"
        ></app-table-interactive>
        <app-table-interactive
          #ti_tiposPromociones
          [tableName]="'tbTiposCodigosPromocionales_tiposPromociones'"
          [parent_pkId]="pkId"
          (guardatabla)="submit(false)"
        ></app-table-interactive>
      </form>
    </div>
  </div>
</div>
