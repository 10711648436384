import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  GruposRecintos,
  IdiomaGR,
} from "@sharedV11/classes/recintos/grupo-recintos";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { EntryTypeService } from "@sharedV11/services/components/entradas-y-promociones/entry-type/entry-type.service";
import { GlobalService } from "@sharedV11/services/global/global.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EditGrupoRecintosService } from "../../../../shared/services/components/grupo-recinto/edit-grupo-recintos.service";

@Component({
  selector: "app-edit-grupo-recintos",
  templateUrl: "./edit-grupo-recintos.component.html",
  styleUrls: ["./edit-grupo-recintos.component.scss"],
})
export class EditGrupoRecintosComponent implements OnInit {
  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: any;
  @Input() mode: any;
  @Input() modificar: boolean;

  editGruposRecintosForm: FormGroup;
  grupoRecinto: GruposRecintos;

  translation: any;
  idiomas: Array<IdiomaGR> = [];
  //Idiomas
  editorListaIdiomas: Array<IdiomaGR>;
  centros: any[] = [];

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  constructor(
    private translator: Translator,
    private fb: FormBuilder,
    public global: GlobalService,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private snackvar_service: SnackbarInfoService,
    private editGruposRecintosService: EditGrupoRecintosService,
    private conectarApiService: ConectarApiService,
    private entryTypeService: EntryTypeService,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    //this.initializeForms()
    this.cdRef.detectChanges();
  }

  async ngOnInit(): Promise<void> {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    await this.inicializarIdiomas();
    this.iniciarCentros();
    this.inicializarIdiomasFormGroup();
    this.manageForm(this.pkId, this.mode);
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  async inicializarIdiomas() {
    await this.conectarApiService.getIdiomasAync().then(
      (idioma) => {
        idioma.DatosResult.ListaIdiomas.map((idio) => {
          this.idiomas.push(idio);
        });
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    localStorage.setItem("Idiomas", this.idiomas.values.toString());
  }

  iniciarCentros() {
    this.entryTypeService
      .getCentros()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((centro) => {
        centro.DatosResult.ListaCentros.map((cent) => {
          this.centros.push(cent);
        });
      });
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };
  }

  get formControls() {
    return this.editGruposRecintosForm.controls;
  }

  initializeFormWithoutData() {
    this.editGruposRecintosForm = this.fb.group({
      pkId: [""],
      Nombre: ["", [Validators.required]],
      CentroId: ["0", [Validators.required]],
      Activo: [false],
      subGrupo: [false],
    });
  }

  inicializarIdiomasFormGroup() {
    this.idiomas.map((idioma) => {
      this.editGruposRecintosForm.addControl(
        "Nombre-" + idioma.CodIdioma,
        new FormControl("")
      );
      this.editGruposRecintosForm.addControl(
        "Descripcion-" + idioma.CodIdioma,
        new FormControl("")
      );
    });
  }

  changeValueForm(grupoRecinto: GruposRecintos) {
    this.formControls["pkId"].setValue(grupoRecinto.pkId);
    this.formControls["Nombre"].setValue(grupoRecinto.Nombre);
    this.formControls["CentroId"].setValue(grupoRecinto.CentroId);
    this.formControls["Activo"].setValue(
      this.global.aBooleano(grupoRecinto.Activo)
    );
    this.formControls["subGrupo"].setValue(
      this.global.aBooleano(grupoRecinto.subGrupo)
    );
    if (grupoRecinto.Idiomas.length > 0) {
      this.idiomas.map((idio) => {
        this.formControls["Nombre-" + idio.CodIdioma].setValue(
          grupoRecinto.Idiomas.find((val) => val.CodIdioma === idio.CodIdioma)
            .Nombre
        );
        this.formControls["Descripcion-" + idio.CodIdioma].setValue(
          grupoRecinto.Idiomas.find((val) => val.CodIdioma === idio.CodIdioma)
            .Descripcion
        );
      });
    }
  }

  manageForm(pkId, mode) {
    if (mode == "I") {
      this.grupoRecinto = this.editGruposRecintosForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.centrosAsignados = this.gsCentros.daTiposCentros(this.grupoRecinto);
      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != undefined) {
      this.serviceCall(pkId);
    }
  }

  valuedChanged() {
    this.editGruposRecintosForm.valueChanges.subscribe((value) => {
      if (this.grupoRecinto != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.grupoRecinto)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }

  serviceCall(pkId) {
    this.editGruposRecintosService
      .findGrupoRecintoPkId(pkId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (gruporecintos) => {
          let grupoRecintos =
            gruporecintos["DatosResult"]["ListaGrupoRecintos"][0];
          if (grupoRecintos != null) {
            this.changeValueForm(grupoRecintos);
            this.grupoRecinto = grupoRecintos;
            this.grupoRecinto.CentrosIds = grupoRecintos.CentrosIds;
            this.valuedChanged();
            this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
            this.centrosAsignados = this.gsCentros.daTiposCentros(
              this.grupoRecinto
            );
            this.loading = false;
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  submit() {
    let grupo: GruposRecintos = this.editGruposRecintosForm.value;

    this.editGruposRecintosForm.markAllAsTouched();
    if (this.editGruposRecintosForm.valid) {
      this.manageApi(grupo, this.mode);
    }
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }

  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.grupoRecinto);
  }

  manageApi(grupo: GruposRecintos, mode) {
    grupo.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
      this.centrosAsignados
    );
    grupo.Activo = this.global.aBooleano(grupo.Activo) == true ? "1" : "0";
    grupo.subGrupo = this.global.aBooleano(grupo.subGrupo) == true ? "1" : "0";

    this.idiomas.map((idioma) => {
      idioma.Nombre = this.formControls["Nombre-" + idioma.CodIdioma].value;
      idioma.Descripcion =
        this.formControls["Descripcion-" + idioma.CodIdioma].value;
    });
    grupo.Idiomas = this.idiomas;

    this.loading = true;
    switch (mode) {
      case "I":
        //insert
        grupo.pkId = "0";
        this.InsGrupoRecintos(grupo);
        break;
      case "U":
        //update
        this.UpdGrupoRecintos(grupo);
        break;
      case "D":
        //duplicate
        grupo.pkId = "0";
        this.InsGrupoRecintos(grupo);
        break;
      default:
        break;
    }
  }
  UpdGrupoRecintos(gruporecinto) {
    this.editGruposRecintosService
      .updGrupoRecintos(gruporecinto, "U")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.nextWindow(response);
      });
  }
  InsGrupoRecintos(gruporecinto) {
    this.editGruposRecintosService
      .insGrupoRecintos(gruporecinto, "I")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.nextWindow(response);
      });
  }

  insUpdGrupoRecintos(grupo: GruposRecintos) {
    this.editGruposRecintosService
      .insUpdGrupoRecintos(grupo, this.idiomas)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  nextWindow(response: any) {
    this.save();
    this.destroy();
    if (response.Mensajes.length > 0) {
      console.table(response.Mensajes);
      response.Mensajes.forEach((element) => {
        this.snackvar_service.openSnackBar(
          element.DescripcionMensaje,
          "",
          "red-snackbar",
          element.CodigoMensaje
        );
      });
    } else {
      this.snackvar_service.openSnackBar(
        this.translation["MensajeSuccessSnackBar"],
        "",
        "green-snackbar"
      );
    }
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.formControls;
    return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
  }

  getErrorMessage(field: string) {
    let mensaje: string;
    let fc_pv = this.editGruposRecintosForm.get(field);
    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        mensaje = this.translation["CampoRequerido"];
      } else if (fc_pv.hasError("pattern")) {
        mensaje = this.translation["SoloNumeros"];
      }
    }
    return mensaje;
  }

  cambiaNombre(evento, idioma) {
    this.idiomas.find((x) => x.CodIdioma == idioma).Nombre = evento;
    //
  }
  cambiaDescripcion(evento, idioma) {
    this.idiomas.find((x) => x.CodIdioma == idioma).Descripcion = evento;
  }

  closeInputFilter(enventValues) {
    this.editGruposRecintosForm
      .get(enventValues.name)
      .setValue(enventValues.value);
  }
}
