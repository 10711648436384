import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  LOCALE_ID,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { AlertService } from "@sharedV11/services/alerts/alert/alert.service";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppDateAdapter } from "../../classes/dataAdapter/date-format.adapter";
import { Filter } from "../../classes/filters/filter";
import { Column } from "../../classes/tables/column";
import { table } from "../../classes/tables/table";
import { FilterService } from "../../services/filter/filter.service";
import { Translator } from "../../services/translator/translator.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MM-YYYY",
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "DD-MM-YYYY",
    dateA11yLabel: "DD-MM-YYYY",
    monthYearA11yLabel: "DD-MM-YYYY",
  },
};

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS,
    },
    { provide: LOCALE_ID, useValue: "es" },
    { provide: MAT_DATE_LOCALE, useValue: "es" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  /* providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }], */
})
export class FilterComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  @Input() tableName: string;
  filters: Filter[] = [];
  translation: any;

  @ViewChild("filtersS") filtersS;

  @ViewChild("filterPanel", { read: ElementRef }) filterPanel: ElementRef;
  @ViewChild("filterButtonOption", { read: ElementRef })
  filterButtonOption: ElementRef;

  @ViewChild("columnButtonOption", { read: ElementRef })
  columnButtonOption: ElementRef;
  @ViewChild("ColumnPanelVisible", { read: ElementRef })
  columnPanelVisible: ElementRef;

  filterLength = 0;

  formControlListaFiltros: FormControl;
  formControlListaColumnas: FormControl;
  editFilterForm: FormGroup;

  panelFiltrosvisible = false;

  panelSelectorFiltrosvisible = false;
  panelColumnasvisible = false;
  numColconFiltro: any;

  constructor(
    private filterService: FilterService,
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private mensajeAlerta: AlertService
  ) {
    this.clickOutSide();
  }
  table: table = {
    tableName: null,
    filterByApi: true,
    maxItems: 10,
    columns: [],
  };

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  ngOnInit(): void {
    this.initializeTranslate();
    this.initializeEditFiltros();

    this.findTable();
    this.filterService.manageFilterGetDataDB(this.tableName);
    this.getTable();
    this.numColconFiltro = this.table.columns.filter((f) => f.filter == true);
  }

  initializeEditFiltros() {
    //this.editFilterForm = new FormControl(this.table.columns.map(elem => elem.name));
    this.editFilterForm = this.fb.group({});
  }
  generateInterfaceForm() {
    this.table.columns.map((column) => {
      if (column.filter == true) {
        if (column.type == "rangeDate") {
          let newFormGroup = this.fb.group({
            [column.name + "Start"]: [""],
            [column.name + "End"]: [""],
          });
          this.editFilterForm.addControl(column.name, newFormGroup);
        } else {
          this.editFilterForm.addControl(column.name, new FormControl(""));
          // this.editFilterForm.registerControl()
        }
      }
    });
  }

  get fEdit() {
    return this.editFilterForm.controls;
  } //Editor

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }
  initializeFiltros() {
    this.formControlListaFiltros = new FormControl(
      this.table.columns.map((elem) => elem.filterActive)
    );
  }
  initializeColumnas() {
    this.formControlListaColumnas = new FormControl(
      this.table.columns.map((elem) => elem.columnActive)
    );
  }

  findTable() {
    this.filterService.findTable(this.tableName);
  }

  changeColumns() {
    this.filterService.changeTable(this.table);
  }

  getTable() {
    this.filterService.table$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.table = data;
        if (this.table) {
          if (this.table.columns != null) {
            this.initializeFiltros();
            this.initializeColumnas();
            this.initializeEditFiltros();
            this.generateInterfaceForm();
            this.filterLength = this.filterLenghtWithoutAColumn();
            this.cdRef.detectChanges();
          }
        }
      });
  }

  MostrarFiltros() {
    this.panelSelectorFiltrosvisible = !this.panelSelectorFiltrosvisible;
    this.panelColumnasvisible = false;
  }
  MostrarColumnas() {
    this.panelColumnasvisible = !this.panelColumnasvisible;
    this.panelSelectorFiltrosvisible = false;
  }
  AplicarFiltros() {
    this.mensajeAlerta.limpiar();
    this.filterByInputFilter();
    this.manageChipsFilter();
    this.filterService.filter(this.tableName, this.filters);

    let filterIndex = this.filters.findIndex((find) => find.name == "keyValue");

    if (filterIndex != -1) this.filterByAll(this.filters[filterIndex].value);
  }
  removeFilter(filter) {
    let filterIndex = this.filters.findIndex(
      (find) => find.filterApi == filter.filterApi
    );
    let filterIndexKey = this.filters.findIndex(
      (find) => find.name == "keyValue"
    );
    if (filterIndexKey != -1) {
      this.filters[filterIndexKey].value = "";
      this.filterByAll(this.filters[filterIndexKey].value);
    } else {
      this.removeDataInput(filter.filterApi);
    }
    this.filters.splice(filterIndex, 1);

    this.filterService.filter(this.tableName, this.filters);
  }
  removeDataInput(name) {
    let nameColumn = this.table.columns.find(
      (find) => find.filterApi == name
    ).name;
    let element = <HTMLInputElement>document.getElementById(nameColumn);

    if (element) {
      element.value = "";
      //this.fEdit[nameColumn].setValue(" ")
      (<HTMLInputElement>(
        document.getElementById(nameColumn + "-hidden")
      )).value = "";
    }
    this.fEdit[nameColumn].reset();
  }
  filterByAll(value) {
    this.mensajeAlerta.limpiar();
    let filterIndex = this.filters.findIndex((find) => find.name == "keyValue");
    if (filterIndex != -1) {
      if (value != "") {
        this.filters[filterIndex].value = value;
        this.filters[filterIndex].show = value;
        this.filterService.filterByAll(this.filters[filterIndex]);
      } else {
        this.filters[filterIndex].value = value;
        this.filterService.filterByAll(this.filters[filterIndex]);
        this.filters.splice(filterIndex, 1);
      }
    } else {
      if (value != "") {
        let filterToAdd = new Filter();
        filterToAdd.value = value;
        filterToAdd.show = value;
        filterToAdd.name = "keyValue";
        filterToAdd.translation = "PalabraClave";
        filterToAdd.filterApi = "keyValue";
        this.pushInFilterToAdd(filterToAdd);
        this.filterService.filterByAll(filterToAdd);
      }
    }
  }
  manageChipsFilter() {
    this.table.columns.map((column) => {
      let filterIndex = this.filters.findIndex(
        (find) => find.filterApi == column.filterApi
      );
      if (filterIndex != -1) {
        if (
          this.fEdit[column.name].value != "" &&
          this.fEdit[column.name].value != undefined
        ) {
          if (column.type == "number") {
            if (isNaN(this.fEdit[column.name].value)) {
              this.filters.splice(filterIndex, 1);
            } else {
              this.filters[filterIndex].value = this.fEdit[column.name].value;
              this.filters[filterIndex].show = this.fEdit[column.name].value;
            }
          } else if (column.type == "rangeDate") {
            if (
              moment(
                this.fEdit[column.name].value[column.name + "Start"]
              ).isValid()
            ) {
              if (this.fEdit[column.name].value[column.name + "End"] != null) {
                if (
                  moment(this.fEdit[column.name].value[column.name + "Start"]) <
                  moment(this.fEdit[column.name].value[column.name + "End"])
                ) {
                  this.filters[filterIndex].value =
                    moment(
                      this.fEdit[column.name].value[column.name + "Start"]
                    ).format("DD/MM/YYYY") +
                    " - " +
                    moment(
                      this.fEdit[column.name].value[column.name + "End"]
                    ).format("DD/MM/YYYY");
                  this.filters[filterIndex].show =
                    this.fEdit[column.name].value;
                }
              } else {
                if (
                  moment(this.fEdit[column.name].value[column.name + "Start"]) <
                  moment()
                ) {
                  this.filters[filterIndex].value =
                    moment(
                      this.fEdit[column.name].value[column.name + "Start"]
                    ).format("DD/MM/YYYY") +
                    " - " +
                    moment().format("DD/MM/YYYY");
                  this.filters[filterIndex].show =
                    this.fEdit[column.name].value;
                }
              }
            } else {
              this.filters.splice(filterIndex, 1);
            }
          } else if (column.type == "date") {
            if (moment(this.fEdit[column.name].value).isValid()) {
              this.filters[filterIndex].value = moment(
                this.fEdit[column.name].value
              ).format("YYYY-MM-DD");
              this.filters[filterIndex].show = moment(
                this.fEdit[column.name].value
              ).format("YYYY-MM-DD");
            } else {
              this.filters.splice(filterIndex, 1);
            }
          } else if (column.type == "select") {
            this.filters[filterIndex].value = this.fEdit[column.name].value;
            this.filters[filterIndex].show =
              column.filterOptions.selectOptions.find(
                (find) => find.value == this.filters[filterIndex].value
              ).name;
          } else {
            this.filters[filterIndex].value = this.fEdit[column.name].value;
            this.filters[filterIndex].show = this.fEdit[column.name].value;
          }
        } else {
          this.filters.splice(filterIndex, 1);
        }
      } else {
        if (column.name != "Actions" && column.filter) {
          if (
            this.fEdit[column.name].value != "" &&
            this.fEdit[column.name].value != undefined
          ) {
            if (column.type == "number") {
              if (!isNaN(this.fEdit[column.name].value)) {
                let filterToAdd = this.createNewFilter(column);
                this.pushInFilterToAdd(filterToAdd);
              }
            } else if (column.type == "rangeDate") {
              if (
                moment(
                  this.fEdit[column.name].value[column.name + "Start"]
                ).isValid()
              ) {
                let filterToAdd = this.createNewFilter(column);

                this.pushInFilterToAdd(filterToAdd);
              }
            } else if (column.type == "date") {
              if (moment(this.fEdit[column.name].value).isValid()) {
                let filterToAdd = this.createNewFilter(column);

                this.pushInFilterToAdd(filterToAdd);
              }
            } else if (column.type == "multiselect") {
              if (this.fEdit[column.name].value) {
                let filterToAdd = this.createNewFilter(
                  column,
                  column.filterOptions
                );
                this.pushInFilterToAdd(filterToAdd);
              }
            } else {
              if (
                this.fEdit[column.name].value &&
                this.fEdit[column.name].value.trim() != ""
              ) {
                let filterToAdd = this.createNewFilter(column);
                this.pushInFilterToAdd(filterToAdd);
              }
            }
          }
        }
      }
    });
  }

  pushInFilterToAdd(data) {
    if (data != null) {
      this.filters.push(data);
    }
  }

  createNewFilter(column: Column, options?) {
    let filterToAdd = new Filter();

    if (column.type == "rangeDate") {
      if (this.fEdit[column.name].value[column.name + "End"] != null) {
        filterToAdd.value =
          moment(this.fEdit[column.name].value[column.name + "Start"]).format(
            "DD/MM/YYYY"
          ) +
          " - " +
          moment(this.fEdit[column.name].value[column.name + "End"]).format(
            "DD/MM/YYYY"
          );
        filterToAdd.show = filterToAdd.value;
      } else {
        if (
          moment(this.fEdit[column.name].value[column.name + "Start"]) <
          moment()
        ) {
          filterToAdd.value =
            moment(this.fEdit[column.name].value[column.name + "Start"]).format(
              "DD/MM/YYYY"
            ) +
            " - " +
            moment().format("DD/MM/YYYY");
          filterToAdd.show = filterToAdd.value;
        } else {
          return null;
        }
      }
    } else if (column.type == "date") {
      filterToAdd.value = moment(this.fEdit[column.name].value).format(
        "YYYY-MM-DD"
      );
      filterToAdd.show = filterToAdd.value;
    } else if (column.type == "select") {
      filterToAdd.value = this.fEdit[column.name].value;
      filterToAdd.show = column.filterOptions.selectOptions.find(
        (find) => find.value == filterToAdd.value
      ).name;
    } else if (column.type == "multiselect") {
      // ["1","2"]
      // preguntar es posible que haya que crear array desde los datos separados por comas para luego
      // convertirlos a integer

      let listaidstring = [];
      let listanombres = [];
      this.fEdit[column.name].value.forEach((element) => {
        listaidstring.push(parseInt(element));
        let n = options.selectOptions.find((f) => f.value == element);
        listanombres.push(n.name);
      });
      //filterToAdd.value = this.fEdit[column.name].setValue(listaidstring.join(','));
      filterToAdd.value = listaidstring.join(",");
      filterToAdd.show = listanombres.join(",");
    } else if (column.type == "inputFilter") {
      filterToAdd.value = this.fEdit[column.name].value;
      filterToAdd.show = column.filterOptions.selectOptions.find(
        (find) => find.value == filterToAdd.value
      ).name;
    } else {
      filterToAdd.value = this.fEdit[column.name].value;
      filterToAdd.show = filterToAdd.value;
    }
    filterToAdd.name = column.name;
    filterToAdd.db = column.BBDD;
    filterToAdd.filterApi = column.filterApi;
    filterToAdd.translation = column.labelTranslate;
    return filterToAdd;
  }
  visibilidadFiltro(evento, seleccionfiltros) {
    if (evento != null && seleccionfiltros != null) {
      let index = this.table.columns.findIndex(
        (item) => item.name == evento.option.value
      );

      if (evento.option.selected) {
        this.table.columns[index].filterActive = evento.option.value;
      } else {
        this.table.columns[index].filterActive = "";
        this.removeDataInput(evento.option.value);
        this.AplicarFiltros();
      }
    }
  }

  visibilidadColumna(evento, seleccionfiltros) {
    if (evento != null && seleccionfiltros != null) {
      let index = this.table.columns.findIndex(
        (item) => item.name == evento.option.value
      );
      this.table.columns[index].columnActive = evento.option.selected;

      if (evento.option.selected) {
        this.table.columns[index].columnActive = evento.option.value;
      } else {
        this.table.columns[index].columnActive = "";
      }

      this.changeColumns();
    }
  }

  filterLenghtWithoutAColumn() {
    let index = undefined;
    if (this.table && this.table.columns)
      index = this.table.columns.find((item) => item.name == "Actions");
    if (index == undefined) {
      return this.table.columns.length;
    } else {
      return this.table.columns.length - 1;
    }
  }
  calculateClassesFiltersButton() {
    let classes: any = { displayBlock: true };
    let flag = false;
    if (this.table && this.table.columns) {
      this.table.columns.map((column) => {
        if (column.filterActive != "") {
          flag = true;
        }
      });
    }
    if (!flag) {
      classes = {
        displayNone: true,
      };
    } else {
      classes = {
        displayBlock: true,
      };
    }

    return classes;
  }
  calculateClassesFiltersTable() {
    let classes: any = { "customan-panel-filtros-tabla": true };
    let flag = false;
    if (this.table && this.table.columns) {
      this.table.columns.map((column) => {
        if (column.filterActive != "") {
          flag = true;
        }
      });
    }

    if (!flag) {
      classes = {
        "customan-panel-filtros-tabla-without-filters": true,
      };
    } else {
      classes = {
        "customan-panel-filtros-tabla": true,
      };
    }

    return classes;
  }

  clickOutSide() {
    this.renderer.listen("window", "click", (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (this.panelSelectorFiltrosvisible) {
        if (
          !this.filterButtonOption.nativeElement.contains(e.target) &&
          !this.filterPanel.nativeElement.contains(e.target)
        ) {
          this.panelSelectorFiltrosvisible = false;
        }
      }

      if (this.panelColumnasvisible) {
        if (
          !this.columnPanelVisible.nativeElement.contains(e.target) &&
          !this.columnButtonOption.nativeElement.contains(e.target)
        ) {
          this.panelColumnasvisible = false;
        }
      }
    });
  }

  filterByInputFilter() {
    this.table.columns.map((column) => {
      let element = <HTMLInputElement>document.getElementById(column.name);
      let elementHidden = <HTMLInputElement>(
        document.getElementById(column.name + "-hidden")
      );
      let filterIndex = this.filters.findIndex(
        (find) => find.filterApi == column.filterApi
      );
      this.filters.splice(filterIndex, 1);
      if (filterIndex != -1 && element) {
        this.fEdit[column.name].setValue("");
        this.fEdit[column.name].reset();
        //this.filters.splice(filterIndex, 1);
      }

      if (element && elementHidden.value != "0" && element.value.trim() != "") {
        this.fEdit[column.name].setValue(elementHidden.value);
        if (
          this.fEdit[column.name].value &&
          this.fEdit[column.name].value.trim() != ""
        ) {
          this.createNewFilter(column);
        }
      }
    });
  }
  closeInputFilter(enventValues) {}

  /*   numeroCamposActivos(tablecolumns) {
    return tablecolumns.find(f => f.filter==true).lenght;
    //return tablecolumns.length;
  } */
}
