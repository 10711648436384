<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
  <app-buttons
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    [buttonsWrap]="'panel'"
    [modificar]="modificar"
  ></app-buttons>
  <div class="editor-registro">
    <div class="divForm">
      <form [formGroup]="editRegistroForm" (onSubmit)="submit()">
        <div class="form-group">
          <div class="col-sm-12 col-md-6">
            <app-input-filter
              *ngIf="
                tiposCodigosPromocionales &&
                tiposCodigosPromocionales.length > 0
              "
              formControlText="TipoCodigoPromocionalId"
              formControlName="TipoCodigoPromocionalId"
              [disabled]="tiposCodigosPromocionales.length < 1"
              [label]="translation.TipoCodigoPromocional"
              [placeHolderTranslation]="translation.TipoCodigoPromocional"
              [list]="tiposCodigosPromocionales"
              textShow="Nombre"
              dataInput="pkId"
              required="true"
              [data]="CodigoPromocional.TipoCodigoPromocionalId"
              (close)="closeInputFilterTipo($event)"
              [disabled]="mode == 'U'"
            ></app-input-filter>
          </div>

          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.Codigo }}</mat-label>
              <input
                matInput
                formControlName="Codigo"
                class="editor-campo-registro"
                formControlName="Codigo"
                [readonly]="CodigoNoAleatorio == '0'"
              />
              <mat-error *ngIf="validateFields('Codigo')">
                {{ getErrorMessage("Codigo") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="form-group">
          <div class="col-sm-12 col-md-6">
            <mat-form-field class="input-select" appearance="outline">
              <mat-label>{{ translation.TipoVenta }}</mat-label>
              <mat-select
                placeholder="{{ translation.TipoVenta }}"
                formControlName="TipoVenta"
              >
                <mat-option *ngFor="let tipo of tiposventa" [value]="tipo.id">
                  {{ translation[tipo.value] }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="validateFields('TipoVenta')">
                {{ getErrorMessage("TipoVenta") }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6">
            <mat-form-field class="input-select" appearance="outline">
              <mat-label>{{ translation.Clase }}</mat-label>
              <mat-select
                placeholder="{{ translation.Clase }}"
                formControlName="Clase"
              >
                <mat-option *ngFor="let clase of clases" [value]="clase.id">
                  {{ translation[clase.value] }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="validateFields('Clase')">
                {{ getErrorMessage("Clase") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!--div class="half">
            <app-input-filter *ngIf="user && userTypes && flagInput" formControlText="Tipo"
            [label]="translation.Tipo" [placeHolderTranslation]="translation.Tipo" 
            [list]="userTypes" textShow="Nombre" dataInput="pkId" required="true"
            [data]="user.Tipo" (close)="closeInputFilter($event)"></app-input-filter>
          </div-->

        <div class="form-group">
          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.FechaInicio }}</mat-label>
              <input
                matInput
                placeholder="{{ translation.PeriodoDeCompra }}"
                formControlName="FechaInicio"
                [matDatepicker]="FechaInicio"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="FechaInicio"
              ></mat-datepicker-toggle>
              <mat-datepicker #FechaInicio></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.FechaFin }}</mat-label>
              <input
                matInput
                formControlName="FechaFin"
                [matDatepicker]="FechaFin"
                placeholder="{{ translation.FechaFin }}"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="FechaFin"
              ></mat-datepicker-toggle>
              <mat-datepicker #FechaFin></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.FechaActivoInicio }}</mat-label>
              <input
                matInput
                placeholder="{{ translation.FechaActivoInicio }}"
                formControlName="FechaActivoInicio"
                [matDatepicker]="FechaActivoInicio"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="FechaActivoInicio"
              ></mat-datepicker-toggle>
              <mat-datepicker #FechaActivoInicio></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.FechaActivoFin }}</mat-label>
              <input
                matInput
                formControlName="FechaActivoFin"
                [matDatepicker]="FechaActivoFin"
                placeholder="{{ translation.FechaActivoFin }}"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="FechaActivoFin"
              ></mat-datepicker-toggle>
              <mat-datepicker #FechaActivoFin></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <!--<div class="col-sm-12 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.Cliente}}</mat-label>
            <input type="number" matInput formControlName="Cliente" class="editor-campo-registro">
            <mat-error *ngIf="validateFields('Cliente')">
              {{getErrorMessage("Cliente")}}
            </mat-error>
          </mat-form-field>
        </div>-->

        <div class="form-group">
          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.Cantidad }}</mat-label>
              <input
                matInput
                type="number"
                formControlName="Cantidad"
                id="Cantidad"
                min="0"
                class="editor-campo-registro"
                color="disabled"
              />
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.CodigoLongitud }}</mat-label>
              <input
                matInput
                type="number"
                formControlName="CodigoLongitud"
                id="CodigoLongitud"
                min="6"
                class="editor-campo-registro"
                color="disabled"
                [readonly]="CodigoNoAleatorio == '1'"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="form-group">
          <div class="col-sm-12 col-md-6">
            <div class="matslide">
              <mat-label
                >Habilitado
                <mat-slide-toggle
                  matInput
                  name="Baja"
                  formControlName="Habilitado"
                ></mat-slide-toggle>
              </mat-label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <!--             <mat-label>{{translation.Divisa}}</mat-label>
            <mat-select placeholder="{{translation.Divisa}}" formControlName="DivisaId" >
              <mat-option *ngFor="let divisa of tiposDivisas " [value]="divisa.pkId">
                {{divisa.Nombre}}
              </mat-option>
            </mat-select> -->
          </div>
        </div>

        <div style="margin: 20px 5px 0 5px; text-align: center">
          <mat-label>{{ translation.Centros }}</mat-label>

          <customan-dual-list
            [source]="centrosSinAsignar"
            [(destination)]="centrosAsignados"
            key="pkId"
            display="Nombre"
            [format]="format"
            [filter]="true"
          >
          </customan-dual-list>
        </div>

        <ng-container *ngIf="mode != 'I'">
          <app-table-interactive
            #ti_tiposEntrada
            [tableName]="'tbCodigoPromocionalEntradas'"
            [parent_pkId]="pkId"
            (guardatabla)="submit(false)"
          ></app-table-interactive>
          <app-table-interactive
            #ti_tiposPromociones
            [tableName]="'tbCodigoPromocionalTipoBono'"
            [parent_pkId]="pkId"
            (guardatabla)="submit(false)"
          ></app-table-interactive>
        </ng-container>

        <ng-container *ngIf="mode == 'I'">
          <app-table-interactive
            #ti_tiposEntrada
            [tableName]="'tbCodigoPromocionalEntradas'"
            [calco_pkId]="calcopkId"
            [calco_table]="'tbTiposCodigosPromocionales_tiposEntrada_calco'"
            (guardatabla)="submit(false)"
          ></app-table-interactive>
          <app-table-interactive
            #ti_tiposPromociones
            [tableName]="'tbCodigoPromocionalTipoBono'"
            [calco_pkId]="calcopkId"
            [calco_table]="'tbTiposCodigosPromocionales_tiposPromociones_calco'"
            [calco_extended]="'tbCodigoPromocionalTiposBonoEntradas_calco'"
            (guardatabla)="submit(false)"
          ></app-table-interactive>
        </ng-container>
      </form>
    </div>
  </div>
</div>
