import {
  ChangeDetectorRef,
  Component,
  Input,
  LOCALE_ID,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  TipoEntrada,
  TiposEntrada_Respuesta,
} from "@sharedV11/classes/clients/client/tipos-entrada-Respuesta.model";
import {
  APP_FORMATOS_FECHA,
  AppDateAdapter,
} from "@sharedV11/classes/dataAdapter/date-format.adapter";
import { IdiomaGR } from "@sharedV11/classes/tarifaResponse/idiomaGR";
import { ButacasService } from "@sharedV11/components/selector-butacas/servicios/butacas.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { RecintosVisitaService } from "@sharedV11/services/components/recintos/recintos-visita/recintos-visita.service";
import { EspectaculosService } from "@sharedV11/services/espectaculos/espectaculos.service";
import { DualListComponent } from "angular-dual-listbox";
import moment from "moment";

@Component({
  selector: "app-edit-sesionesconbutacas",
  templateUrl: "./edit-sesionesconbutacas.component.html",
  styleUrls: ["./edit-sesionesconbutacas.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_FORMATOS_FECHA,
    },
    { provide: LOCALE_ID, useValue: "es" },
    { provide: MAT_DATE_LOCALE, useValue: "es" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class EditSesionesConButacasComponent implements OnInit {
  [x: string]: any;

  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;
  idiomas: Array<IdiomaGR> = [];

  editRegistroForm: FormGroup;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  tiposTiposEntradaAPI: TiposEntrada_Respuesta;

  // Tipo Entrada
  tiposTipoEntrada: Array<TipoEntrada>;
  //entradas dual-list
  entradasSinAsignar: any = [];
  entradasAsignadas: any = [];

  grSinAsignar: any = [];
  grAsignadas: any = [];

  lasesion: any;
  listaEspectaculos: any = [];
  listaEspectaculos2: any = [];
  listaGruposRecinto;
  datosespectaculo: any;
  idespectaculo: string;
  FechaSesionStr: string;
  HoraSesion: string;

  constructor(
    private espectaculoService: EspectaculosService,
    private fb: FormBuilder,
    private translator: Translator,
    private panelService: PanelService,
    private snackvar_service: SnackbarInfoService,
    private httpErrorService: HttpErrorsService,
    private butacas_service: ButacasService,
    /*  private gsCentros: GlobalServicCentros, */
    private conectarApiService: ConectarApiService,
    private recintosVisitaService: RecintosVisitaService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngOnInit() {
    await this.CargarEspectaculos();
    this.conectarApiService.getGruposRecintos().subscribe((data) => {
      this.listaGruposRecinto = data.DatosResult.ListaGrupoRecintos;
    });
    this.butacas_service.getEstadosButacas(); // necesario del servicio butacas.service
  }

  /*   async loadCentros() {
    await this.gsCentros.getCentros();
  } */

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };
  }

  async initializeFormWithoutData() {
    this.editRegistroForm = this.fb.group({
      pkId: [""],
      iEspectaculoId: ["", Validators.required],
      pkIdGR: ["", Validators.required],
      dtFecha: [""],
      dtHoraInicio: ["23:59"],
      Activa: [""],
    });
  }

  public setDate(event, formControl): void {
    //
    this.editRegistroForm.controls[formControl].setValue(
      moment(event.value).format("YYYY/MM/DD 0:00:00")
    );
  }

  getFechaBaja(): Date {
    return this.aFechaddmmyyyy(
      moment(this.editRegistroForm.controls["dtFecha"].value).format(
        "DD/MM/YYYY 0:00:00"
      )
    );
  }

  aFechaddmmyyyy(fecha: string): Date {
    //
    //
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      var parts = fecha.split("/");
      if (parts[0] != "Invalid date") {
        //
        //
        return new Date(
          parseInt(parts[2].substring(0, 4)),
          parseInt(parts[1]) - 1,
          parseInt(parts[0])
        );
      } else {
        return null;
      }
    }
  }

  get formControls() {
    return this.editRegistroForm.controls;
  }

  manageForm(pkId, mode) {
    if (mode == "I") {
      this.lasesion = this.editRegistroForm.value;
      //this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != null) {
      this.findSesion(pkId);
    }
  }

  findSesion(pkId) {
    this.espectaculoService
      .findSesionByPkId(pkId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (SesionApi) => {
          // let Categoria:Categoria =  CategoriaApi["DatosResult"]["customanUsuarios"][0];
          localStorage.setItem('chNombreRecinto', SesionApi.DatosResult.sesiones[0].chNombreRecinto)
          let Sesion = SesionApi["DatosResult"]["sesiones"][0];

          if (Sesion != null) {
            Sesion.dtHoraInicio = Sesion.dtHoraInicio.trim();
            this.changeValueForm(Sesion);
            this.lasesion = this.editRegistroForm.value;

            //this.Categoria.CentrosIds = Espectaculo.CentrosIds;
            this.valuedChanged();
            this.loading = false;
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  cambiaNombre(evento, idioma) {
    this.idiomas.find((x) => x.CodIdioma == idioma).Nombre = evento;
    //
  }

  changeValueForm(sesion: any) {
    this.editRegistroForm.get("pkId").setValue(sesion.pkId);

    this.editRegistroForm.get("iEspectaculoId").setValue(sesion.iEspectaculoId);
    this.editRegistroForm.get("pkIdGR").setValue(sesion.pkIdGR);
    this.editRegistroForm.get("dtFecha").setValue(sesion.dtFecha);

    if (moment(sesion.dtHoraInicio).isValid()) {
      this.editRegistroForm
        .get("dtHoraInicio")
        .setValue(moment(sesion.dtHoraInicio).format("HH:mm"));
    } else {
      if (sesion.dtHoraInicio == "") sesion.sesion = "23:59";
      this.editRegistroForm.get("dtHoraInicio").setValue(sesion.dtHoraInicio);
    }

    this.datosespectaculo = this.listaEspectaculos2.find(
      (p) => p.EspectaculosId == sesion.iEspectaculoId
    );
    this.FechaSesionStr = sesion.dtFecha;
    this.HoraSesion = sesion.dtHoraInicio;
    /*  this.Mapa = this.listaEspectaculos2.find( p => p.pkId=this.idespectaculo).chPathMapaSVG; */

    this.editRegistroForm
      .get("Activa")
      .setValue(sesion.bActiva == "1" ? true : false);
  }

  valuedChanged() {
    this.editRegistroForm.valueChanges.subscribe((value) => {
      if (this.lasesion != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.lasesion)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }
  submit() {
    this.editRegistroForm.markAllAsTouched();
    let lasesion: any = this.editRegistroForm.value;
    if (this.editRegistroForm.valid) {
      this.manageApi(lasesion, this.mode);
    }
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }

  save() {
    this.loading = false;
    this.panelService.setDiscardChanges(true);

    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.lasesion);
  }

  manageApi(Sesion: any, mode) {
    //Espectaculo.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);
    Sesion.bActivo = Sesion.Activo == true ? "1" : "0";
    delete Sesion.Activo;

    if (moment(Sesion.dtFecha).isValid()) {
      Sesion.dtFecha = moment(Sesion.dtFecha).format("DD/MM/yyyy");
    }

    let parts = Sesion.dtHoraInicio.split(":");
    Sesion.dtHoraInicio = parts[0] + ":" + parts[1];

   
    this.loading = true;
    switch (mode) {
      case "I":
        //insert
        Sesion.pkId = "0";
        this.InsSesionButaca(Sesion);
        break;
      case "U":
        //update
        this.UpdSesionButaca(Sesion);

        break;
      case "D":
        //duplicate
        Sesion.pkId = "0";
        this.DelSesionButaca(Sesion);

        break;

      default:
        break;
    }
  }
  UpdSesionButaca(Sesion) {
    this.espectaculoService
      .updSesionButaca(Sesion, "U")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  InsSesionButaca(Sesion) {
    this.espectaculoService
      .insSesionButaca(Sesion, "I")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  DelSesionButaca(Sesion) {}

  nextWindow(response: any) {
    const err = this.existErrors();
    if (!this.existErrors()) {
      this.save();
      this.destroy();
      if (response.Mensajes.length > 0) {
        response.Mensajes.forEach((element) => {
          this.snackvar_service.openSnackBar(
            element.DescripcionMensaje,
            "",
            "red-snackbar",
            element.CodigoMensaje
          );
        });
      } else {
        this.snackvar_service.openSnackBar(
          this.translation["MensajeSuccessSnackBar"],
          "",
          "green-snackbar"
        );
      }
    }
  }
  existErrors() {
    return this.editRegistroForm.get("pkId").errors ||
      this.editRegistroForm.get("iEspectaculoId").errors ||
      this.editRegistroForm.get("pkIdGR").errors
      ? true
      : false;
  }
  getErrorMessage(field: string) {
    let fc_pv = this.editRegistroForm.get(field);
    if (fc_pv) {
      if (fc_pv.errors) {
        if (fc_pv.errors.required) {
          return this.translation["CampoRequerido"];
        } else if (field === "Empleado") {
          if (fc_pv.hasError("pattern")) {
            return this.translation["SoloNumeros"];
          }
        } else if (field === "Nombre") {
          if (fc_pv.hasError("pattern")) {
            return this.translation["SoloLetras"];
          }
        }
      }
    }

    return "Error";
  }

  async CargarEspectaculos() { //tipo 0-null:Todas, 1:Recinto, 2:Taller/actividad
    let resultado: boolean = false;
    this.espectaculoService.getEspectaculos().subscribe(
      (data) => {
        this.listaEspectaculos = data.DatosResult.espectaculos;
        //this. listaEspectaculos2 =  this.listaEspectaculos;
        data.DatosResult.espectaculos.forEach((e) => {
          let espectaculo = {
            EspectaculosId: e["pkId"].toString(),
            chNombreEspectaculo: e.chNombreEspectaculo,
            NombreFicheroSVG: e.chPathMapaSVG,
          };
          this.listaEspectaculos2.push(espectaculo);
        });

        this.initializeFormWithoutData();
        this.initializeTranslate();

        this.manageForm(this.pkId, this.mode);
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );

    //return resultado;
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.editRegistroForm.controls;
    return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
  }

  closeInputFilter(enventValues) {
    this.editRegistroForm.get(enventValues.name).setValue(enventValues.value);
  }
}
