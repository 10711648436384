<form [formGroup]="editorRegistroForm" autocomplete="off">
  <mat-form-field appearance="outline">
    <mat-label>{{ label }}</mat-label>

    <input
      matInput
      placeholder="{{ placeHolderTranslation }}"
      name="list"
      id="{{ formControlText }}"
      (blur)="closeInput()"
      formControlName="answer"
      [disabled]="disabled"
      (input)="OnChange()"
    />
    <mat-error *ngIf="f.answer.errors?.required || f.answer.invalid"
      >*</mat-error
    >
    <datalist id="{{ idList }}">
      <option *ngFor="let item of listCopy" title="{{ item[dataInput] }}">
        {{ item[textShow] }}
      </option>
    </datalist>

    <input
      type="hidden"
      name="input"
      formControlName="answerHidden"
      id="{{ formControlText }}-hidden"
    />
  </mat-form-field>
</form>
