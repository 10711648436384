import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { Globales } from "@clasesV6/globales";
import { GrupoTiposEntrada } from "@sharedV11/classes/EntradasPromociones/GrupoEntradas/GrupoTiposEntrada";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { ApiTokenService } from "@sharedV11/services/api/token/api-token.service";
import { EditGruposTipoEntradaService } from "@sharedV11/services/edit_grupos_tipo_entrada/edit-grupos-tipo-entrada.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Translator } from "src/app/shared/services/translator/translator.service";

@Component({
  selector: "app-edit-grupos-tipo-entrada-v11",
  templateUrl: "./edit-grupos-tipo-entrada-v11.component.html",
  styleUrls: ["./edit-grupos-tipo-entrada-v11.component.scss"],
})
export class EditGruposTipoEntradaV11Component implements OnInit {
  loading = true;
  private ngUnsubscribe = new Subject();
  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;
  editGrupoEntrada: FormGroup;
  gruposTipoEntrada: GrupoTiposEntrada;
  globales: Globales;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };
  grupoTipoEntrada: GrupoTiposEntrada;

  constructor(
    private editGrupoEntradaService: EditGruposTipoEntradaService,
    private translator: Translator,
    private servicioAPI: ConectarApiService,
    private fb: FormBuilder,
    private panelService: PanelService,
    private tokenService: ApiTokenService,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode);
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };
  }
  changeValueForm(fp: GrupoTiposEntrada) {
    this.formControls["pkId"].setValue(fp.pkId);
    this.formControls["NombreGrupo"].setValue(fp.NombreGrupo);
    this.formControls["Descripcion"].setValue(fp.Descripcion);
    this.formControls["ConMotivo"].setValue(this.stringToBoolean(fp.ConMotivo));
  }
  initializeFormWithoutData() {
    this.editGrupoEntrada = this.fb.group({
      pkId: [""],
      NombreGrupo: ["", Validators.required],
      Descripcion: [""],
      ConMotivo: [false],
    });
  }
  stringToBoolean(value) {
    if (typeof value !== "boolean") {
      if (value == "1") {
        value = true;
      } else {
        value = false;
      }
    }
    return value;
  }
  get formControls() {
    return this.editGrupoEntrada.controls;
  }
  manageForm(pkId, mode) {
    if (mode == "I") {
      this.gruposTipoEntrada = this.editGrupoEntrada.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != null) {
      this.findGrupoTipoEntrada(pkId);
    }
  }
  findGrupoTipoEntrada(pkId) {
    this.editGrupoEntradaService
      .findGrupoEntradaByPkId(pkId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userApi) => {
        let grupoTipoEntradaData: GrupoTiposEntrada =
          userApi.DatosResult.customanGrupoTipoEntrada[0];
        if (grupoTipoEntradaData != null) {
          this.changeValueForm(grupoTipoEntradaData);
          this.grupoTipoEntrada = grupoTipoEntradaData;
          this.grupoTipoEntrada.CentrosIds = grupoTipoEntradaData.CentrosIds;
          this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
          this.centrosAsignados = this.gsCentros.daTiposCentros(
            this.grupoTipoEntrada
          );
          this.loading = false;
        }
      });
  }
  valuedChanged() {
    this.editGrupoEntrada.valueChanges.subscribe((value) => {
      if (this.gruposTipoEntrada != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.gruposTipoEntrada)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }
  manageApi(gruposTipoEntrada: GrupoTiposEntrada, mode) {
    gruposTipoEntrada.CentrosIds =
      this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);
    this.loading = true;
    switch (mode) {
      case "I":
        //insert
        gruposTipoEntrada.pkId = "0";
        this.InsGruposTipoEntrada(gruposTipoEntrada);
        break;
      case "U":
        //update
        this.UpdGruposTipoEntrada(gruposTipoEntrada);

        break;
      case "D":
        //duplicate
        gruposTipoEntrada.pkId = "0";
        this.InsGruposTipoEntrada(gruposTipoEntrada);
        break;

      default:
        break;
    }
  }
  UpdGruposTipoEntrada(gruposTipoEntrada) {
    this.editGrupoEntradaService
      .updGruposTipoEntrada(gruposTipoEntrada, "U")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.save();
          this.destroy();
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  InsGruposTipoEntrada(gruposTipoEntrada) {
    this.editGrupoEntradaService
      .insGruposTipoEntrada(gruposTipoEntrada, "I")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.save();
          this.destroy();
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  submit() {
    let gruposTipoEntrada: GrupoTiposEntrada = this.editGrupoEntrada.value;
    gruposTipoEntrada.ConMotivo =
      gruposTipoEntrada.ConMotivo.toString().toLowerCase() == "true"
        ? "1"
        : "0";
    this.manageApi(gruposTipoEntrada, this.mode);
  }
  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }

  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.gruposTipoEntrada);
  }
}
