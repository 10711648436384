import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeLayoutComponent } from './shared/layouts/home-layout/home-layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


//material
import { MaterialModule } from './shared/material/material.module';
import { SidemenuComponent } from './shared/layouts/sidemenu/sidemenu.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestInterceptor } from './shared/interceptors/requestInterceptor';
import { PortadaComponent } from './components/portada/portada.component';
import { AlertComponent } from './shared/components/alert/alert.component';
import { BreadCrumbsComponent } from './shared/layouts/breadcrumbs/breadcrumbs.component';
import { PopupService } from './shared/services/alerts/popup/popup.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

//Flex Layout
//import { ThemeSwitchComponent } from './theme-switch/theme-switch.component';
import { from } from 'rxjs';
import { ButtonsComponent } from './shared/components/buttons/buttons.component';

import { UserProfileV11Component } from './components/user/user-profile-v11/user-profile-v11.component';
import { FilterComponent } from './shared/components/filter/filter.component';
import { UserV11Component } from './components/user/user-v11/user-v11.component';
import { config } from 'rxjs';
import { ApiTokenService } from './shared/services/api/token/api-token.service';
import { PanelCustoman } from './shared/panel-customan/panel.customan';
import { TableComponent } from './shared/components/tables/table/table.component';
import { TarifaV11Component } from './components/tarifas/tarifa-v11/tarifa-v11.component';
import { EditGroupComponent } from './components/tarifas/internet-groups/edit-group/edit-group.component';
import { InternetGroupComponent } from './components/tarifas/internet-groups/internet-group.component';

//V6
import { AlertaComponent } from './V6/shared/_alerta/alerta.component';
import { ProfileSaveComponent } from './components/user/user-profile-v11/profile-save/profile-save.component';
import { TiposControlComponent } from './V6/maestros/tipos-control/tipos-control.component';
import { ClientesModule } from './V6/clientes/clientes.module';
import { FiltrosGenericosComponent } from '@sharedV6/filtros-genericos/filtros-genericos.component';




import { GrupoTipoEntradaComponent } from './V6/entradas/grupo-tipo-entrada/grupo-tipo-entrada.component';
import { GrupoTipoBonoComponent } from './V6/entradas/grupo-tipo-bono/grupo-tipo-bono.component';
import { ItemsMotivosGruposComponent } from './V6/entradas/items-motivos-grupos/items-motivos-grupos.component';
import { TiposEntradaComponent } from './V6/entradas/tipos-entrada/tipos-entrada.component';
import { TiposPromocionComponent } from './V6/entradas/tipos-promocion/tipos-promocion.component';
import { TarifasComponent } from './V6/tarifas/tarifas/tarifas.component';
import { GruposInternetComponent } from './V6/tarifas/grupos-internet/grupos-internet.component';
import { UsuariosComponent } from './V6/usuarios/usuarios/usuarios.component';
import { GruposActividadTallerComponent } from './V6/recintos/grupos-actividad-taller/grupos-actividad-taller.component';
import { RecintosComponent } from './V6/recintos/recintos/recintos.component';
import { TiposSesionComponent } from './V6/recintos/tipos-sesion/tipos-sesion.component';
import { SesionesComponent } from './V6/recintos/sesiones/sesiones.component';
import { GuardarPopupComponent } from './V6/recintos/recintos/guardar-popup/guardar-popup.component';
import { EditUserComponent } from './components/user/user-v11/edit-user/edit-user.component';
import { ClientV11Component } from './components/clients/client-v11/client-v11.component';
import { FormasPagoV11Component } from './components/clients/formas-pago-v11/formas-pago-v11.component';
import { DialogoPopUp } from '@sharedV11/components/alert/dialog/popup.service';
import { EditClientComponent } from './components/clients/client-v11/edit-client/edit-client.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { CustomanDualListComponent } from '@sharedV11/components/customanduallist/customan-dual-list.component';
import { CommonModule } from '@angular/common';
import { ActivarClienteComponent } from './components/clients/client-v11/activar-cliente/activar-cliente.component';
import { EditTarifaComponent } from './components/tarifas/tarifa-v11/edit-tarifa/edit-tarifa.component';
import { TypeAccessControlComponent } from './components/mestres/type-access-control/type-access-control.component';
import { EditFormasPagoV11Component } from './components/clients/formas-pago-v11/edit-formas-pago-v11/edit-formas-pago-v11.component';
import { SessionTypeComponent } from './components/recintos/tipossesion/sessiontype.component';
import { EditSessionTypeComponent } from './components/recintos/tipossesion/edit-sessiontype/edit-sessiontype.component';
import { EditSinButacasComponent } from './components/recintos/sinbutacas/edit-sinbutacas/edit-sinbutacas.component';
import { SinButacasComponent } from './components/recintos/sinbutacas/sinbutacas.component';
import { ClientGroupComponent } from './components/clients/client-group/client-group.component';
import { EditClientGroupComponent } from './components/clients/client-group/edit-client-group/edit-client-group.component';
import { GruposTipoEntradaV11Component } from './components/entradaspromociones/grupos-tipo-entrada-v11/grupos-tipo-entrada-v11.component';
import { EntryTypeComponent } from './components/entradaspromociones/entry-type/entry-type.component';
import { EditEntryTypeComponent } from './components/entradaspromociones/entry-type/edit-entry-type/edit-entry-type.component';
import { RecintosVisitaComponent } from './components/recintos/recintos-visita/recintos-visita.component';
import { TipoBonoComponent } from './components/entradaspromociones/tipobono/tipobono.component';
import { EditTipoBonoComponent } from './components/entradaspromociones/tipobono/edit-tipobono/edit-tipobono.component';
import { EditRecintosVisitaComponent } from './components/recintos/recintos-visita/edit-recintos-visita/edit-recintos-visita.component';
import { ItemsMotivosGruposV11Component } from './components/entradaspromociones/items-motivos-grupos-v11/items-motivos-grupos-v11.component';
import { EditItemsMotivosGruposV11Component } from './components/entradaspromociones/items-motivos-grupos-v11/edit-items-motivos-grupos-v11/edit-items-motivos-grupos-v11.component';
import { EditGruposTipoEntradaV11Component } from './components/entradaspromociones/grupos-tipo-entrada-v11/edit-grupos-tipo-entrada-v11/edit-grupos-tipo-entrada-v11.component';
import { GrupoTipoBonoV11Component } from './components/entradaspromociones/grupo-tipo-bono-v11/grupo-tipo-bono-v11.component';
import { EditGrupoTipoBonoV11Component } from './components/entradaspromociones/grupo-tipo-bono-v11/edit-grupo-tipo-bono-v11/edit-grupo-tipo-bono-v11.component';
import { InputFilterComponent } from './shared/components/input-filter/input-filter.component';
import { GrupoRecintosComponent } from './components/recintos/grupo-recintos/grupo-recintos.component';
import { EditGrupoRecintosComponent } from './components/recintos/grupo-recintos/edit-grupo-recintos/edit-grupo-recintos.component';
import { GruposActividadTallerV11Component } from './components/recintos/grupos-actividad-taller-v11/grupos-actividad-taller-v11.component';
import { EditGruposActividadTallerV11Component } from './components/recintos/grupos-actividad-taller-v11/edit-grupos-actividad-taller-v11/edit-grupos-actividad-taller-v11.component';
import { EmailComponent } from './shared/components/dialogs/email/email.component'
import { MatDialogRef } from '@angular/material/dialog';
import { CategoriasComponent } from './components/mestres/categorias/categorias.component';
import { EditCategoriasComponent } from './components/mestres/categorias/edit-categorias/edit-categorias.component';
import { TipoCodigoPromocionesComponent } from './components/codigos-promocionales/tipo-codigo-promociones/tipo-codigo-promociones.component';
import { EditTipoCodigoPromocionesComponent } from './components/codigos-promocionales/tipo-codigo-promociones/edit-tipo-codigo-promociones/edit-tipo-codigo-promociones.component';
import { TableInteractiveComponent } from './shared/components/tables/table-interactive/table-interactive.component';
import { CodigoPromocionalComponent } from './components/codigos-promocionales/codigo-promocional/codigo-promocional.component';
import { EditCodigoPromocionalComponent } from './components/codigos-promocionales/codigo-promocional/edit-codigo-promocional/edit-codigo-promocional.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { JSON2ControlComponent } from '@sharedV11/components/json2control/json2control.component';
import { ApiWaitComponent } from '@sharedV11/components/dialogs/api_wait/api_wait.component';
import { TpvsComponent } from './components/mestres/tpvs/tpvs.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DiascierreComponent } from './components/mestres/diascierre/diascierre.component';
import { EditDiascierreComponent } from './components/mestres/diascierre/edit-diascierre/edit-diascierre.component';
import { TiposreservaComponent } from './components/tarifas/tiposreserva/tiposreserva.component';
import { EditTiposreservaComponent } from './components/tarifas/tiposreserva/edit-tiposreserva/edit-tiposreserva.component';
import { EditTpvsComponent } from './components/mestres/tpvs/edit-tpvs/edit-tpvs.component';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { EditClientTypeComponent } from './components/clients/client-type/edit-client-type/edit-client-type.component';
import { ClientTypeComponent } from './components/clients/client-type/client-type.component';
import { EditAccessControlTypeComponent } from './components/mestres/type-access-control/edit-type-access-control/edit-type-access-control.component';
import { EspectaculosComponent } from './components/espacios/espectaculos/espectaculos.component';
import { EditEspectaculosComponent } from './components/espacios/espectaculos/edit-espectaculos/edit-espectaculos.component';
import { SesionesConButacasComponent } from './components/espacios/sesionesconbutacas/sesionesconbutacas.component';
import { EditSesionesConButacasComponent } from './components/espacios/sesionesconbutacas/edit-sesionesconbutacas/edit-sesionesconbutacas.component';
import { TranslatePipe } from './shared/translate.pipe';
import { ComponentsModule } from './components/components.module';
import { SharedModule } from '@sharedV11/shared.module';
/* import { SelectorButacasComponent } from '@sharedV11/components/selector-butacas/selector-butacas.component';
import { MapaRecintoComponent } from '@sharedV11/components/selector-butacas/componentes/mapa-recinto/mapa-recinto.component';
import { MinimapaRecintoComponent } from '@sharedV11/components/selector-butacas/componentes/minimapa-recinto/minimapa-recinto.component';
import { SelectorNoNumeradasComponent } from '@sharedV11/components/selector-butacas/componentes/selector-no-numeradas/selector-no-numeradas.component';
import { ModalComponent } from '@sharedV11/components/selector-butacas/modal/modal.component';
import { ModalNONumeradoComponent } from '@sharedV11/components/selector-butacas/modal-no-numerado/modal-no-numerado.component';
import { SliderPreciosComponent } from '@sharedV11/components/selector-butacas/componentes/slider-precios/slider-precios.component';
import { NouisliderModule } from 'ng2-nouislider'; */
import { SelectorButacasModule } from '@sharedV11/components/selector-butacas/selector-butacas.module';
import { MyCurrencyPipe } from '@sharedV11/my-currency.pipe';
import { GruposTarifasComponent } from './components/tarifas/grupos-tarifas/grupos-tarifas.component';
import { EditGruposTarifasComponent } from './components/tarifas/grupos-tarifas/edit-grupos-tarifas/edit-grupos-tarifas.component';
import { InputDecimalDirective } from './directives/input-decimal.directive';
import { ReglasDeNegocioComponent } from './components/entradaspromociones/reglas-de-negocio/reglas-de-negocio.component';
import { EditReglasDeNegocioComponent } from './components/entradaspromociones/reglas-de-negocio/edit-reglas-de-negocio/edit-reglas-de-negocio.component';
import { PositiveIntegerDirective } from './directives/positive-integer.directive';
import { CustomDecimalNumberDirective } from './directives/custom-decimal-number.directive';


const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    SidemenuComponent,
    LoginComponent,
    PortadaComponent,
    AlertComponent,
    BreadCrumbsComponent,
    PopupService,
    DialogoPopUp,
    ButtonsComponent,
    UserProfileV11Component,
    FilterComponent,
    TableComponent,
    JSON2ControlComponent,
    UserV11Component,
    TarifaV11Component,
    SessionTypeComponent, //V11
    EditSessionTypeComponent, //V11
    SinButacasComponent,
    EditSinButacasComponent,
    PanelCustoman,
    UsuariosComponent,//, //V6
    TiposControlComponent, //V6
    //,ItemsMotivosGruposComponent //V6
    UsuariosComponent, //V6
    ProfileSaveComponent, //V6
  
    //AlertaComponent, //V6
    GrupoTipoEntradaComponent,//V6
    GrupoTipoBonoComponent,//V6
    //ItemsMotivosGruposComponent,//V6
    TiposEntradaComponent,//V6
    TiposPromocionComponent,
    TarifasComponent,
    GruposInternetComponent,
    GruposActividadTallerComponent,
    RecintosComponent,
    TiposSesionComponent,
    SesionesComponent,
    GuardarPopupComponent,
    EditUserComponent,
    ClientV11Component,
    FormasPagoV11Component,
    EditClientComponent,
    EditGroupComponent,
    InternetGroupComponent,
    CustomanDualListComponent,
    ActivarClienteComponent,
    EditTarifaComponent,
    TypeAccessControlComponent,
    EditAccessControlTypeComponent,
    EditFormasPagoV11Component,
    ClientGroupComponent,
    EditFormasPagoV11Component,
    EditClientGroupComponent,
    EditFormasPagoV11Component,    
    ClientGroupComponent,
    GruposTipoEntradaV11Component,
    EditFormasPagoV11Component,
    EntryTypeComponent,
    EditEntryTypeComponent,
    RecintosVisitaComponent,
    TipoBonoComponent,
    EditTipoBonoComponent,
    EditRecintosVisitaComponent,
    ItemsMotivosGruposV11Component,
    EditItemsMotivosGruposV11Component,
    EditGruposTipoEntradaV11Component,
    GrupoTipoBonoV11Component,
    EditGrupoTipoBonoV11Component,
    InputFilterComponent,
    GrupoRecintosComponent,
    EditGrupoRecintosComponent,
    GruposActividadTallerV11Component,
    EditGruposActividadTallerV11Component,
    EmailComponent,
    CategoriasComponent,
    EditCategoriasComponent,
    TipoCodigoPromocionesComponent,
    EditTipoCodigoPromocionesComponent,
    TableInteractiveComponent,
    CodigoPromocionalComponent,
    EditCodigoPromocionalComponent,
    ChangePasswordComponent,
    ApiWaitComponent,
    NewPasswordComponent,
    TpvsComponent,
    EditTpvsComponent,
    DiascierreComponent,
    EditDiascierreComponent,
    TiposreservaComponent,
    EditTiposreservaComponent,
    GruposTarifasComponent,
    EditGruposTarifasComponent,
    ClientTypeComponent,
    EditClientTypeComponent,
    EspectaculosComponent,
    EditEspectaculosComponent,
    SesionesConButacasComponent,
    EditSesionesConButacasComponent,
    InputDecimalDirective,
    ReglasDeNegocioComponent,
    EditReglasDeNegocioComponent,
    PositiveIntegerDirective,
    CustomDecimalNumberDirective,
    //Selector Butacas 
    
/*     SelectorButacasComponent,
    MapaRecintoComponent,
    MinimapaRecintoComponent,
    SelectorNoNumeradasComponent,
    ModalComponent,
    ModalNONumeradoComponent,
    SliderPreciosComponent,  */
    //NouisliderModule 


    ],
  imports: [
    BrowserModule, 
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    AngularDualListBoxModule,
    FormsModule,
    FlexLayoutModule,
    NgxMaskModule.forRoot(maskConfig),
    RecaptchaModule,
    SelectorButacasModule,
    SharedModule
    //FiltrosGenericosComponent
    //ClientesModule

  ],
  exports: [],
  entryComponents: [
    PopupService,
    DialogoPopUp,
    MatPaginator,
    
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: "6LfXS2IUAAAAADr2WUPQDzAnTEbSQzE1Jxh0Zi0a",
      } as RecaptchaSettings,
    },
    TranslatePipe,
    MyCurrencyPipe, 
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    /* {
      provide: APP_INITIALIZER,
      useFactory:(config:ApiTokenService) => () => config.getConexionPrivada(),
      deps:[ApiTokenService],
      multi:true
    } */
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
/* {
  provide: APP_INITIALIZER,
  useFactory:(config:ApiTokenService) => () => config.getConexionPrivada(),
  deps:[ApiTokenService],
  multi:true
} */