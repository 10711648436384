<!--Tabla-->
<div class="component">
  <alert></alert>
  <div
    class="mat-elevation-z8 overflow-table"
    [ngClass]="{ 'is-invalid': !tablavalida }"
  >
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div class="tablebutton" style="text-align: right">
        <button
          mat-icon-button
          color="primary"
          *ngIf="
            (!table['InsertDisabled'] && !insertando) ||
            (!table['InsertDisabled'] && insertando && guardarcambios)
          "
          [matTooltip]="translation.AnadirEntrada"
          (click)="anadirEntrada()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

    <div id="div-table" *ngIf="table != undefined && showTable == true">
      <mat-paginator
        *ngIf="pagination"
        [length]="paginatorLength"
        [pageSize]="paginatorSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        [showFirstLastButtons]="true"
      ></mat-paginator>
      <mat-progress-bar
        *ngIf="!tableLoaded"
        mode="indeterminate"
      ></mat-progress-bar>

      <table
        [ngClass]="{ zonaoculta: tableLoaded === false }"
        mat-table
        [dataSource]="dataSource"
        matSort
        #inttable
        multiTemplateDataRows
      >
        <ng-container *ngFor="let item of table.columns">
          <ng-container
            *ngIf="item.columnActive == item.name || tableLoaded"
            matColumnDef="{{ item.name }}"
          >
            <ng-container *ngIf="item.name == 'Actions'">
              <th mat-header-cell *matHeaderCellDef>
                {{ translation[item.labelTranslate] }}
              </th>

              <td mat-cell *matCellDef="let row; let i = dataIndex">
                <div
                  class="action-container"
                  [id]="'col_' + i + '_' + item.name"
                >
                  <ng-container
                    *ngFor="let item_button of item.filterOptions.actions"
                  >
                    <button
                      *ngIf="!item_button['disabled']"
                      (click)="actionTableManage(item_button.action, row, i)"
                      #tooltip="matTooltip"
                      [id]="'button_' + i + '_' + item.name"
                      [matTooltip]="item_button.matTooltip"
                      [matTooltipPosition]="'above'"
                      class="button-icon"
                      style="width: 34px; height: 34px"
                    >
                      <mat-icon class="icon-actions-tables ennegro">{{
                        item_button.icon
                      }}</mat-icon>
                    </button>
                  </ng-container>

                  <div *ngIf="table['Expandable']">
                    <button
                      mat-icon-button
                      (click)="
                        expandedElement = expandedElement === row ? null : row
                      "
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container *ngIf="item.name != 'Actions'">
              <ng-container *ngIf="item.hasOwnProperty('editfunc')">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ translation[item.labelTranslate] }}
                </th>
                <!-- bloque de lectura -->
                <ng-container [ngSwitch]="item.type">
                  <ng-container *ngSwitchCase="'string'">
                    <td
                      mat-cell
                      *matCellDef="let row; let i = dataIndex"
                      (click)="editRow(row)"
                    >
                      <ng-container *ngIf="item['editfunc'] == 'insertonly'">
                        <input
                          matInput
                          #txtVal
                          type="text"
                          class="editor-campo-registro"
                          [value]="row[item.name]"
                          (input)="
                            somethingChanged($event, txtVal.value, i, item.name)
                          "
                          disabled="true"
                        />
                      </ng-container>
                      <ng-container *ngIf="item['editfunc'] != 'insertonly'">
                        <input
                          matInput
                          #txtVal
                          type="text"
                          class="editor-campo-registro"
                          [value]="row[item.name]"
                          (input)="
                            somethingChanged($event, txtVal.value, i, item.name)
                          "
                        />
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container *ngSwitchCase="'number'">
                    <td
                      mat-cell
                      *matCellDef="let row; let i = dataIndex"
                      (click)="editRow(row)"
                    >
                      <ng-container *ngIf="item['editfunc'] == 'insertonly'">
                        <input
                          matInput
                          #numVal
                          type="number"
                          step="any"
                          [min]="item['min'] ? item['min'] : 0"
                          class="editor-campo-registro"
                          [value]="row[item.name]"
                          (input)="
                            somethingChanged($event, numVal.value, i, item.name)
                          "
                          (focusout)="
                            focusOutFunction(
                              $event,
                              numVal.value,
                              i,
                              item.name,
                              row
                            )
                          "
                          disabled="true"
                          style="text-align: right"
                          [id]="'col_' + i + '_' + item.name"
                        />
                      </ng-container>
                      <ng-container *ngIf="item['editfunc'] != 'insertonly'">
                        <input
                          matInput
                          #numVal
                          type="number"
                          step="any"
                          [min]="item['min'] ? item['min'] : 0"
                          class="editor-campo-registro"
                          [value]="row[item.name]"
                          (input)="
                            somethingChanged($event, numVal.value, i, item.name)
                          "
                          (focusout)="
                            focusOutFunction(
                              $event,
                              numVal.value,
                              i,
                              item.name,
                              row
                            )
                          "
                          style="text-align: right"
                          [id]="'col_' + i + '_' + item.name"
                        />
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container *ngSwitchCase="'select'">
                    <td
                      mat-cell
                      *matCellDef="let row; let i = dataIndex"
                      (click)="editRow(row)"
                    >
                      <ng-container *ngIf="item['editfunc'] == 'insertonly'">
                        <mat-select
                          #selVal
                          class="editor-campo-registro"
                          [value]="row[item.name]"
                          (selectionChange)="
                            somethingChanged($event, selVal.value, i, item.name)
                          "
                          disabled="true"
                        >
                          <mat-option
                            *ngFor="
                              let option of item.filterOptions.selectOptions
                            "
                            value="{{ option.value }}"
                            >{{ option.name }}</mat-option
                          >
                        </mat-select>
                      </ng-container>
                      <ng-container *ngIf="item['editfunc'] != 'insertonly'">
                        <mat-select
                          #selVal
                          class="editor-campo-registro"
                          [value]="row[item.name]"
                          (selectionChange)="
                            somethingChanged($event, selVal.value, i, item.name)
                          "
                        >
                          <mat-option
                            *ngFor="
                              let option of item.filterOptions.selectOptions
                            "
                            value="{{ option.value }}"
                            >{{ option.name }}</mat-option
                          >
                        </mat-select>
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container *ngSwitchCase="'slidetoggle'">
                    <td
                      mat-cell
                      *matCellDef="let row; let i = dataIndex"
                      (click)="editRow(row)"
                      style="text-align: center"
                    >
                      <ng-container *ngIf="item['editfunc'] == 'insertonly'">
                        <mat-slide-toggle
                          #slideVal
                          class="editor-campo-registro"
                          [checked]="row[item.name]"
                          (change)="
                            somethingChangedSlideToggle(
                              $event,
                              slideVal.checked,
                              i,
                              item.name
                            )
                          "
                          disabled="true"
                        >
                        </mat-slide-toggle>
                      </ng-container>
                      <ng-container *ngIf="item['editfunc'] != 'insertonly'">
                        <mat-slide-toggle
                          #slideVal
                          class="editor-campo-registro"
                          [checked]="row[item.name]"
                          (change)="
                            somethingChangedSlideToggle(
                              $event,
                              slideVal.checked,
                              i,
                              item.name
                            )
                          "
                        >
                        </mat-slide-toggle>
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container *ngSwitchCase="'date'">
                    <td
                      mat-cell
                      *matCellDef="let row; let i = dataIndex"
                      (click)="editRow(row)"
                    >
                      <mat-form-field appearance="outline">
                        <input
                          #datVal
                          matInput
                          [matDatepicker]="picker"
                          [value]="row[item.name].value"
                          (dateChange)="
                            somethingChangedDate(
                              $event,
                              datVal.value,
                              i,
                              item.name
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container *ngSwitchCase="'hour'">
                    <td
                      mat-cell
                      *matCellDef="let row; let i = dataIndex"
                      (click)="editRow(row)"
                    >
                      <ng-container *ngIf="item['editfunc'] == 'insertonly'">
                        <input
                          matInput
                          #horVal
                          type="time"
                          class="editor-campo-registro"
                          [value]="row[item.name]"
                          (input)="
                            somethingChanged($event, horVal.value, i, item.name)
                          "
                          disabled="true"
                          style="text-align: right"
                        />
                      </ng-container>
                      <ng-container *ngIf="item['editfunc'] != 'insertonly'">
                        <input
                          matInput
                          #horVal
                          type="time"
                          class="editor-campo-registro"
                          [value]="row[item.name]"
                          (input)="
                            somethingChanged($event, horVal.value, i, item.name)
                          "
                          style="text-align: right"
                        />
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container *ngSwitchCase="'inputFilter'">
                    <td
                      mat-cell
                      *matCellDef="let row; let i = dataIndex"
                      (click)="editRow(row)"
                    >
                      <mat-progress-bar
                        style="top: 38px"
                        mode="buffer"
                        *ngIf="!item.filterOptions.selectOptions"
                      ></mat-progress-bar>

                      <ng-container
                        *ngIf="
                          item['editfunc'] == 'insertonly' && row.pkId != ''
                        "
                      >
                        <app-input-filter
                          *ngIf="item.filterOptions.selectOptions"
                          formControlText="{{ item.name }}_{{ i }}"
                          [label]=""
                          disabled="true"
                          [placeHolderTranslation]="
                            translation[item.placeHolderTranslate]
                          "
                          [list]="item.filterOptions.selectOptions"
                          textShow="name"
                          dataInput="value"
                          required="false"
                          (close)="closeInputFilter($event, i, item.name)"
                          [data]="row[item.name]"
                        ></app-input-filter>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          row.pkId == '' || item['editfunc'] != 'insertonly'
                        "
                      >
                        <app-input-filter
                          *ngIf="item.filterOptions.selectOptions"
                          formControlText="{{ item.name }}_{{ i }}"
                          [label]=""
                          [placeHolderTranslation]="
                            translation[item.placeHolderTranslate]
                          "
                          [list]="item.filterOptions.selectOptions"
                          textShow="name"
                          dataInput="value"
                          required="false"
                          (close)="closeInputFilter($event, i, item.name)"
                          [data]="row[item.name]"
                        ></app-input-filter>
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <td mat-cell *matCellDef="let row" (click)="editRow(row)">
                      {{ row[item.name] }}
                    </td>
                  </ng-container>
                </ng-container>
                <!-- Bloque de lectura FIN -->
              </ng-container>

              <ng-container *ngIf="!item.hasOwnProperty('editfunc')">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ translation[item.labelTranslate] }}
                </th>
                <td mat-cell *matCellDef="let row" (click)="editRow(row)">
                  {{ row[item.name] }}
                </td>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container
          *ngIf="!table['Expandable']"
          matColumnDef="expandedDetail"
        >
          <td
            mat-cell
            *matCellDef="let row"
            [attr.colspan]="columnsToDisplay.length"
          ></td>
        </ng-container>

        <ng-container
          *ngIf="table['Expandable'] == 'Idiomas'"
          matColumnDef="expandedDetail"
        >
          <td
            mat-cell
            *matCellDef="let row; let i = dataIndex"
            [attr.colspan]="columnsToDisplay.length"
          >
            <div
              class="example-element-detail"
              [@detailExpand]="
                row != expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <!-- -->
              <!--  <h2>{{translation.Idioma}}</h2> -->
              <ng-container *ngIf="table['Expandable'] == 'Idiomas'">
                <mat-accordion
                  *ngFor="let idioma of editorListaIdiomas; let j = index"
                >
                  <mat-expansion-panel [expanded]="j == 0">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{ idioma.Nombre }}
                      </mat-panel-title>
                      <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>

                    <mat-form-field appearance="outline">
                      <mat-label
                        >{{ translation.Nombre }} ({{
                          idioma.CodIdioma
                        }})</mat-label
                      >

                      <input
                        matInput
                        #idionombre
                        type="text"
                        placeholder="{{ translation.Nombre }} en {{
                          idioma.Nombre
                        }}"
                        [value]="getDatoIdioma(row, idioma.CodIdioma, 'Nombre')"
                        (input)="
                          idiomaChanged(
                            $event,
                            idionombre.value,
                            i,
                            idioma.CodIdioma,
                            'Nombre'
                          )
                        "
                      />
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                      <mat-label
                        >{{ translation.Descripcion }} ({{
                          idioma.CodIdioma
                        }})</mat-label
                      >
                      <textarea
                        matInput
                        type="text"
                        placeholder="{{ translation.Descripcion }} {{
                          idioma.Nombre
                        }}"
                        [value]="
                          getDatoIdioma(row, idioma.CodIdioma, 'Descripcion')
                        "
                        (input)="
                          idiomaChanged(
                            $event,
                            idionombre.value,
                            i,
                            idioma.CodIdioma,
                            'Descripcion'
                          )
                        "
                      >
                      </textarea>
                    </mat-form-field>
                  </mat-expansion-panel>
                </mat-accordion>
              </ng-container>
              <!-- -->
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngIf="table['Expandable'] == 'tabla'"
          matColumnDef="expandedDetail"
        >
          <td
            mat-cell
            *matCellDef="let row; let i = dataIndex"
            [attr.colspan]="columnsToDisplay.length"
          >
            <div
              class="example-element-detail"
              [@detailExpand]="
                row == expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <!-- -->
              <!--  <h2>{{translation.Idioma}}</h2> -->
              <ng-container *ngIf="table['Expandable'] == 'tabla'">
                <app-table-interactive
                  *ngIf="!modecalco"
                  #ti_subtabla
                  class="subtabla"
                  [tableName]="table['ExpandableTable']"
                  [parent_pkId]="parent_pkId"
                  [subparent_pkId]="row.pkId"
                  (guardatabla)="submit(this, false)"
                  (totales)="ActualizarTotales($event)"
                  (cargadatabla)="ActualizarTotales($event)"
                ></app-table-interactive>

                <app-table-interactive
                  *ngIf="modecalco && modelcalcoextended"
                  #ti_subtabla
                  class="subtabla"
                  [tableName]="calco_extended"
                  [parent_pkId]="parent_pkId"
                  [subparent_pkId]="row.pkId"
                  (guardatabla)="submit(this, false)"
                  (totales)="ActualizarTotales($event)"
                  (cargadatabla)="ActualizarTotales($event)"
                ></app-table-interactive>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr
          mat-row
          *matRowDef="let row; let i = dataIndex; columns: columnsToDisplay"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === row"
          (click)="expandRow($event, row)"
          [id]="'row_' + i"
        ></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: ['expandedDetail']"
          class="example-detail-row"
        ></tr>
      </table>
    </div>
  </div>
</div>
<!--Panel-->
<app-panelcustoman
  *ngIf="customanpanel !== undefined"
  [ConfigPanel]="customanpanel"
  [pkId]="customanPkId"
  [mode]="customanMode"
>
</app-panelcustoman>
