<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
  <app-buttons
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    [buttonsWrap]="'panel'"
    [modificar]="modificar"
  ></app-buttons>
  <div class="divForm">
    <form [formGroup]="editGroupModelForm" (onSubmit)="submit()">
      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.Codigo }}</mat-label>
            <input
              matInput
              type="number"
              min="0"
              formControlName="CodigoTipoControl"
              (change)="actualizarCodigoenTabla($event)"
            />
            <mat-error matInput *ngIf="validateFields('CodigoTipoControl')">
              {{ getErrorMessage("CodigoTipoControl") }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.Nombre }}</mat-label>
            <input matInput formControlName="Nombre" />
            <mat-error matInput *ngIf="validateFields('Nombre')">
              {{ getErrorMessage("Nombre") }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.Descripcion }}</mat-label>
            <input matInput formControlName="Descripcion" />
            <mat-error matInput *ngIf="validateFields('Descripcion')">
              {{ getErrorMessage("Descripcion") }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-12">
          <!--       <mat-form-field class="input-select" appearance="outline">
        <mat-label>{{translation.NivelEntrada}}</mat-label>
        <mat-select placeholder="{{translation.NivelEntrada}}"
          formControlName="NivelEntrada" name="NivelEntrada">
          <mat-option value="1">Normal</mat-option>
          <mat-option value="2">Reducidad</mat-option>
          <mat-option value="3">Gratuita</mat-option>
          <mat-option value="4">Normal Ida y Vuelta</mat-option>
          <mat-option value="5">Reducidad Ida y Vuelta</mat-option>
          <mat-option value="6">Gratuita Ida y Vuelta</mat-option>
        </mat-select>
      </mat-form-field> -->

          <mat-form-field class="input-select" appearance="outline">
            <mat-select
              placeholder="{{ translation.NivelEntrada }}"
              formControlName="NivelEntrada"
              name="NivelEntrada"
            >
              <mat-option
                *ngFor="let tiponivelentrada of globales.arr_nivlelesEntrada"
                [value]="tiponivelentrada.id"
              >
                {{ tiponivelentrada.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-card>
            <mat-card-subtitle>{{
              translation.Comprobaciones
            }}</mat-card-subtitle>
            <mat-card-content>
              <div class="row flex justify-content-between">
                <mat-slide-toggle
                  name="CompHoraria"
                  formControlName="CompHoraria"
                  >{{ translation.Hora }}
                </mat-slide-toggle>
                <mat-slide-toggle
                  matInput
                  name="PermisoSinFecha"
                  formControlName="PermisoSinFecha"
                  style="width: 200px"
                  >{{ translation.SinFecha }}</mat-slide-toggle
                >
              </div>

              <div class="row flex justify-content-between">
                <mat-slide-toggle
                  matInput
                  name="CompDiaria"
                  formControlName="CompDiaria"
                  >{{ translation.Diaria }}</mat-slide-toggle
                >
                <mat-slide-toggle
                  matInput
                  name="CompDiaSemana"
                  formControlName="CompDiaSemana"
                  style="width: 200px"
                  (change)="diasemana($event)"
                  >{{ translation.DiaSemana }}</mat-slide-toggle
                >
              </div>

              <div class="row flex justify-content-between">
                <mat-slide-toggle
                  matInput
                  name="CompIntervalo"
                  formControlName="CompIntervalo"
                  (change)="reseteaIntervalos($event)"
                  >{{ translation.Intervalo }}</mat-slide-toggle
                >
                <mat-slide-toggle
                  matInput
                  name="CompPasoPrevio"
                  formControlName="CompPasoPrevio"
                  (change)="reseteaPasosPrevios($event)"
                  style="width: 200px"
                  >{{ translation.PasoPrevio }}</mat-slide-toggle
                >
              </div>

              <div class="row flex justify-content-between">
                <mat-slide-toggle
                  #caldias
                  matInput
                  name="CompCalendario"
                  formControlName="CompCalendario"
                  >{{ translation.Calendario }}</mat-slide-toggle
                >
                <mat-slide-toggle
                  #calhoras
                  matInput
                  name="CompCalendHorario"
                  formControlName="CompCalendHorario"
                  (change)="CalendarioHorasChange($event)"
                  style="width: 200px"
                  >{{ translation.CalendHorario }}</mat-slide-toggle
                >
              </div>
              <div class="row flex justify-content-between">
                <mat-slide-toggle
                  matInput
                  name="DenegarAcceso"
                  formControlName="DenegarAcceso"
                  style="width: 200px"
                  >{{ translation.DenegarAcceso }}</mat-slide-toggle
                >
                <div></div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <!--       <div class="col-sm-12 col-md-6"  [ngClass]="{'ocultardiasd': formControls.CompDiaSemana.value==false }">
 -->
        <div class="col-sm-12 col-md-6">
          <mat-card>
            <mat-card-subtitle>{{
              translation.DiasConDisponibilidad
            }}</mat-card-subtitle>
            <mat-card-content>
              <div class="row flex justify-content-between">
                <mat-slide-toggle
                  name="AccesoLunes"
                  formControlName="AccesoLunes"
                  [disabled]="formControls.CompDiaSemana.value == false"
                  >{{ translation.Lunes }}</mat-slide-toggle
                >
                <mat-slide-toggle
                  matInput
                  name="AccesoMartes"
                  formControlName="AccesoMartes"
                  [disabled]="formControls.CompDiaSemana.value == false"
                  >{{ translation.Martes }}</mat-slide-toggle
                >
              </div>

              <div class="row flex justify-content-between">
                <mat-slide-toggle
                  matInput
                  name="AccesoMiercoles"
                  formControlName="AccesoMiercoles"
                  [disabled]="formControls.CompDiaSemana.value == false"
                  >{{ translation.Miercoles }}</mat-slide-toggle
                >
                <mat-slide-toggle
                  matInput
                  name="AccesoJueves"
                  formControlName="AccesoJueves"
                  [disabled]="formControls.CompDiaSemana.value == false"
                  >{{ translation.Jueves }}</mat-slide-toggle
                >
              </div>

              <div class="row flex justify-content-between">
                <mat-slide-toggle
                  matInput
                  name="AccesoViernes"
                  formControlName="AccesoViernes"
                  [disabled]="formControls.CompDiaSemana.value == false"
                  >{{ translation.Viernes }}</mat-slide-toggle
                >
                <mat-slide-toggle
                  matInput
                  name="AccesoSabado"
                  formControlName="AccesoSabado"
                  [disabled]="formControls.CompDiaSemana.value == false"
                  >{{ translation.Sabado }}</mat-slide-toggle
                >
              </div>

              <div class="row flex justify-content-between">
                <mat-slide-toggle
                  matInput
                  name="AccesoDomingo"
                  formControlName="AccesoDomingo"
                  [disabled]="formControls.CompDiaSemana.value == false"
                  >{{ translation.Domingo }}</mat-slide-toggle
                >
                <div></div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-12">
          <mat-slide-toggle name="PorDefecto" formControlName="PorDefecto"
            >{{ translation.PorDefecto }}
          </mat-slide-toggle>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field
            appearance="outline"
            class="claseintervalo"
            [ngClass]="{
              ocultarelemento: formControls.CompIntervalo.value == false
            }"
          >
            <mat-label>{{ translation.DiasIntervaloDesde }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="DiasIntervaloDesde"
              [readonly]="formControls.CompIntervalo.value == false"
              min="0"
            />
            <mat-error matInput *ngIf="validateFields('DiasIntervaloDesde')">
              {{ getErrorMessage("DiasIntervaloDesde") }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6">
          <mat-form-field
            appearance="outline"
            class="claseintervalo"
            [ngClass]="{
              ocultarelemento: formControls.CompIntervalo.value == false
            }"
          >
            <mat-label>{{ translation.DiasIntervaloHasta }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="DiasIntervalo"
              [readonly]="formControls.CompIntervalo.value == false"
              min="1"
            />
            <mat-error matInput *ngIf="validateFields('DiasIntervalo')">
              {{ getErrorMessage("DiasIntervalo") }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field
            appearance="outline"
            [ngClass]="{
              ocultarelemento: formControls.CompPasoPrevio.value == false
            }"
          >
            <mat-label>{{ translation.NPasosPrevios }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="NumPasosPrevios"
              [readonly]="formControls.CompPasoPrevio.value == false"
              min="1"
              value=""
            />
            <mat-error matInput *ngIf="validateFields('NumPasosPrevios')">
              {{ getErrorMessage("NumPasosPrevios") }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6">
          <mat-form-field
            appearance="outline"
            [ngClass]="{
              ocultarelemento: formControls.CompPasoPrevio.value == false
            }"
          >
            <mat-label>{{ translation.NPasosPreviosDiarios }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="NumPasosPreviosDiarios"
              [readonly]="formControls.CompPasoPrevio.value == false"
              min="1"
              value=""
            />
            <mat-error
              matInput
              *ngIf="validateFields('NumPasosPreviosDiarios')"
            >
              {{ getErrorMessage("NumPasosPreviosDiarios") }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!--     <div class="form-group row">
      <div class="col-sm-12">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.DiasConDisponibilidad}}</mat-label>
          <mat-select placeholder="{{translation.DiasEnLosQueEstaraDisponible}}" formControlName="tipoControlDias"
            name="tipoDias" multiple 
            (selectionChange)="CambioDiasSemanaMultiple($event)">
            <mat-option value="1">{{translation.Lunes}}</mat-option>
            <mat-option value="2">{{translation.Martes}}</mat-option>
            <mat-option value="3">{{translation.Miercoles}}</mat-option>
            <mat-option value="4">{{translation.Jueves}}</mat-option>
            <mat-option value="5">{{translation.Viernes}}</mat-option>
            <mat-option value="6">{{translation.Sabado}}</mat-option>
            <mat-option value="7">{{translation.Domingo}}</mat-option>
          </mat-select>
          <mat-hint>
            {{translation.ParaSeleccionarTodaLaSemanaHayQueDejarTodosLosDiasSinMarcar}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div> -->

      <!--     <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{translation.Codigo}}</mat-label>
        <input matInput formControlName="CodigoTipoControl">
        <mat-error matInput *ngIf="validateFields('CodigoTipoControl')">
          {{getErrorMessage("CodigoTipoControl")}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{translation.Descripcion}}</mat-label>
        <input matInput formControlName="Nombre">
        <mat-error matInput *ngIf="validateFields('Nombre')">
          {{getErrorMessage("Nombre")}}
        </mat-error>
      </mat-form-field>
    </div> -->

      <mat-card
        [ngClass]="{ ocultatabla: caldias.checked === false }"
        style="margin-top: 10px"
      >
        <mat-card-subtitle>{{ translation.Calendario }}</mat-card-subtitle>
        <mat-card-content>
          <app-table-interactive
            *ngIf="CodigoTipoControl || mode == 'I'"
            #ti_tiposControlCalendario
            [tableName]="'Calendariodias_tiposcontrol'"
            [parent_pkId]="CodigoTipoControl"
            (guardatabla)="submitCal(false)"
            (cargadatabla)="tableloaded(ti_tiposControlCalendario)"
          >
          </app-table-interactive>
        </mat-card-content>
      </mat-card>

      <mat-card
        [ngClass]="{ ocultatabla: calhoras.checked === false }"
        style="margin-top: 10px"
      >
        <mat-card-subtitle>{{ translation.CalendHorario }}</mat-card-subtitle>
        <mat-card-content>
          <app-table-interactive
            *ngIf="CodigoTipoControl || mode == 'I'"
            #ti_tiposControlCalendarioHoras
            [tableName]="'Calendariohoras_tiposcontrol'"
            [parent_pkId]="CodigoTipoControl"
            (guardatabla)="submitCalHoras(false)"
            (cargadatabla)="tableloaded(ti_tiposControlCalendarioHoras)"
          >
          </app-table-interactive>
        </mat-card-content>
      </mat-card>

      <div style="margin: 20px 5px 0 5px; text-align: center">
        <mat-label>{{ translation.Centros }}</mat-label>

        <customan-dual-list
          [source]="centrosSinAsignar"
          [(destination)]="centrosAsignados"
          key="pkId"
          display="Nombre"
          [format]="format"
          [filter]="true"
        >
        </customan-dual-list>
      </div>
    </form>
  </div>
</div>
