import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { LayoutService } from "../../services/layouts/layout.service";
import { Translator } from "../../services/translator/translator.service";

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"],
})
export class BreadCrumbsComponent implements OnInit {
  opened: boolean = true;
  infobreadcrumb: any[] = [];
  currentRoute: string;
  breadarray: any[] = []; //string
  breadlength: number;
  translation: any;
  layoutservices: LayoutService;

  allData1: any[] = [];
  allData2: any[] = [];
  allData3: any[] = [];

  constructor(
    private autentificador: AuthenticationService,
    private router: Router,
    private _ruta: ActivatedRoute,
    private _layoutservices: LayoutService,
    private changeDetectorRef: ChangeDetectorRef,
    private translator: Translator
  ) {
    this.initializeTranslate();
    var breadcrumb: string[];
    var breadcrumb2: string[];
    var breadcrumb3: string[];

    this.layoutservices = _layoutservices;
    for (var i = 0; i < this.router.config.length; i++) {
      breadcrumb = [];
      if (this.router.config[i].data && this.router.config[i].data.breadcrumbs)
        this.router.config[i].data.breadcrumbs.forEach((element) => {
          breadcrumb.push(element);
        });
      this.allData1.push({
        path: "/" + this.router.config[i].path,
        breadcrumbs: breadcrumb,
      });
    }

    if (this.router.config[0])
      for (var i = 0; i < this.router.config[0].children.length; i++) {
        breadcrumb = [];
        if (
          this.router.config[0].children[i].data &&
          this.router.config[0].children[i].data.breadcrumbs
        )
          this.router.config[0].children[i].data.breadcrumbs.forEach(
            (element) => {
              breadcrumb.push(element);
            }
          );
        this.allData2.push({
          path: "/" + this.router.config[0].children[i].path,
          breadcrumbs: breadcrumb,
        });

        let breadcrumb2 = breadcrumb.slice();
        if (this.router.config[0].children[i].children) {
          for (
            var j = 0;
            j < this.router.config[0].children[i].children.length;
            j++
          ) {
            if (this.router.config[0].children[i].path == "Clientes")
              breadcrumb = breadcrumb2.slice();
            if (
              this.router.config[0].children[i].children[j].data &&
              this.router.config[0].children[i].children[j].data &&
              this.router.config[0].children[i].children[j].data.breadcrumbs
            )
              this.router.config[0].children[i].children[
                j
              ].data.breadcrumbs.forEach((element) => {
                breadcrumb.push(element);
              });

            //this.allData3.push({path:this.router.config[0].children[i].path +'_i='+i+'_/_'+ 'j='+j+'_'+this.router.config[0].children[i].children[j].path,
            this.allData3.push({
              path:
                "/" +
                this.router.config[0].children[i].path +
                "/" +
                this.router.config[0].children[i].children[j].path,

              breadcrumbs: breadcrumb,
            });
          }
        }
      }

    this.allData1.forEach((d) => this.infobreadcrumb.push(d));
    this.allData2.forEach((d) => this.infobreadcrumb.push(d));
    this.allData3.forEach((d) => this.infobreadcrumb.push(d));

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event["url"];
        let breadtmp;
        if (this.currentRoute.includes("/editar")) {
          breadtmp = this.infobreadcrumb.filter(
            (e) => e.path == "/Clientes/Cliente/editar/:id"
          );
        } else {
          breadtmp = this.infobreadcrumb.filter(
            (e) => e.path == this.currentRoute
          );
        }
        // breadtmp = this.infobreadcrumb.filter(e => e.path == this.currentRoute);
        this.breadarray = breadtmp[0]["breadcrumbs"];
        this.breadarray = this.breadarray.map((s) => {
          var ret;
          if (this.translation[s["crumb"]] !== undefined)
            ret = {
              crumb: this.translation[s["crumb"]],
              urlcrumb: s["urlcrumb"],
            };
          //ret = s;
          else ret = { crumb: "'" + s["crumb"] + "'", urlcrumb: s["urlcrumb"] };
          return ret;
        });
        this.breadlength = this.breadarray.length;
      }
    });

    _layoutservices.sidebar_opened.subscribe((o) => {
      this.opened = o;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit() {
    // this.initializeTranslate();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  clickCrumb(u) {
    this.layoutservices.urlclicked.emit(u);
  }
}
